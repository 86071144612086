html {
    --text-color: #c0dfff;
    --border-color: #003f5f;
    --bg-color-on: #005e89;
    --bg-color-off: #002337;
    font-size: 16px;
    font-family: sans-serif;
    color: var(--text-color);
    background-color: black;
}

#app {
    margin: 0 auto;
    max-width: min(75em, 100vw)
}

button, input {
    background: none;
    color: inherit;
    border: .125em solid var(--border-color);
    border-radius: .25em;
    padding: 1em;
    margin: .5em;
    font: inherit;
}

.device-control:disabled {
}