.Vu3zZG_ControlGroupContainer {
  flex-direction: column;
  display: flex;
}

.Vu3zZG_ControlGroupList {
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.Vu3zZG_ControlGroupListItem {
  display: flex;
}

._9DvXDa_ModeControl {
  flex-direction: column;
  align-items: center;
  margin: 1em;
  display: flex;
}

._9DvXDa_ModeControlButton {
  min-width: 5em;
  min-height: 5em;
  background-color: var(--bg-color-off);
  border-radius: 50%;
}

.ezWxSa_DeviceControl {
  flex-direction: column;
  align-items: center;
  margin: 1em;
  display: flex;
}

.ezWxSa_DeviceControlButton {
  background: linear-gradient(to top, var(--bg-color-off) 0 var(--brightness), black var(--brightness) 100%);
  min-width: 5em;
  min-height: 5em;
  border-radius: 50%;
}

.ezWxSa_DeviceControlButtonOn {
  background: linear-gradient(to top, var(--bg-color-on) 0 var(--brightness), var(--bg-color-off) var(--brightness) 100%);
}

.ezWxSa_DeviceControlBrightnessModal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, calc(1 - var(--newBrightnessValue) / 200) );
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.ezWxSa_DeviceControlBrightnessModalBrightnessText {
  font-size: 4em;
}

.ezWxSa_DeviceControlBrightnessModalDeviceName {
  font-size: 2em;
}

html {
  --text-color: #c0dfff;
  --border-color: #003f5f;
  --bg-color-on: #005e89;
  --bg-color-off: #002337;
  color: var(--text-color);
  background-color: #000;
  font-family: sans-serif;
  font-size: 16px;
}

#app {
  max-width: min(75em, 100vw);
  margin: 0 auto;
}

button, input {
  color: inherit;
  border: .125em solid var(--border-color);
  font: inherit;
  background: none;
  border-radius: .25em;
  margin: .5em;
  padding: 1em;
}

/*# sourceMappingURL=index.65956d93.css.map */
